import React from 'react';
import './Order.css';
import Order_Module from './Order_Module.js';
import order_bg from './img/order_bg.png';
import back_button from './img/back_button.png';
import box_options from './img/fruit_boxes/box_options.png';
import size_options from './img/fruit_boxes/size_options.png';
import box1 from './img/fruit_boxes/box1.png';
import box2 from './img/fruit_boxes/box2.png';
import box3 from './img/fruit_boxes/box3.png';
//import mira_logo from './img/mira-logo-mini.png';


var valid_zip_codes = {
  sf:["94102","94103","94105","94107","94108","94109","94110","94111","94112","94114","94115","94116","94117","94118","94121","94122","94123","94124","94127","94129","94131","94132","94133","94134","94158"],
  oakland:["94601","94602","94603","94605","94606","94607","94608","94609","94610","94611","94612","94618","94619","94621"],
  oakland_ext:["94619","94621","94605"],
  berkeley:["94710","94703","94702","94705","94704","94720","94709","94706","94708","94707"],
  alameda:["94501"],
  treasure_island:["94130"]
};

var zip_code_responses = {
  "San Francisco":"✅ Great! We deliver to your area of San Francisco. Our deliveries usually happen Sunday-Wednesday and you'll be able to let us know your delivery preferences when you place your order.",
  "Treasure Island":"💔 At the moment we're not currently delivering to Treasure Island, but we'd like to! Contact us at the bottom of the page if you're interested in a delivery to your Treasure Island address.",
  "Oakland":"✅ Great! We deliver to your area of Oakland. Our deliveries usually happen Sunday-Wednesday and you'll be able to let us know your delivery preferences when you place your order.",
  "Eastern Oakland":"💔 At the moment we're not currently delivering to your area in Oakland, but we'd like to! Contact us at the bottom of the page if you're interested in a delivery to your Oakland address.",
  "Alameda":"✅ Great! We deliver to your area of Alameda. Our deliveries usually happen Sunday-Wednesday and you'll be able to let us know your delivery preferences when you place your order",
  "Berkeley":"✅ Great! We deliver to your area of Berkeley. Our deliveries usually happen Sunday-Wednesday and you'll be able to let us know your delivery preferences when you place your order",
  "No Delivery":"💔At the moment we're not currently delivering to your area. If you'd like more information, send us a message using the form at the bottom of the page."
}


class Order extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      current_order_type:false
    };
  }
  start_residential = () => {
    this.refs.child.start_order("Residential");
  };
  start_business = () => {
    this.refs.child.start_order("Business")
  }
  set_order_type(order_type){
    this.setState({
      current_order_type:order_type
    });
  }
  render() {

    if(this.props.currentPage != "order"){
      return false;
    }

    var order_type = this.state.current_order_type;
      
    return <section id="order" className="hidden">
        <div className="section_title">
          <div className="title_text">PLACE AN ORDER</div>
          <div className="title_subtext upper"></div>
        </div>
        <div className="toggle_tab">
          <div className={"tab"+(order_type=="Residential"?" active":"")} onClick={this.start_residential.bind(this)}>RESIDENTIAL</div>
          <div className={"tab"+(order_type=="Business"?" active":"")} onClick={this.start_business.bind(this)}>BUSINESS</div>
          <div className="dot"></div>
        </div>
          <Order_Module
            get_current_order_type={this.set_order_type.bind(this)}
            ref="child"/>
    </section>;

  }
}


export default Order;



function is_valid_zipcode(zipcode){
  var isValidZip = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zipcode);
  return isValidZip;
}

function is_approved_zipcode(zipcode){
  var valid_code = false;
  var can_deliver = false;
  if(valid_zip_codes.sf.includes(zipcode)){
    valid_code = "San Francisco";
    can_deliver = true;
  }else if(valid_zip_codes.treasure_island.includes(zipcode)){
    valid_code = "Treasure Island";
    can_deliver = false;
  }else if(valid_zip_codes.oakland.includes(zipcode)){
    valid_code = "Oakland";
    can_deliver = true;
  }else if(valid_zip_codes.berkeley.includes(zipcode)){
    valid_code = "Berkeley";
    can_deliver = true;
  }else if(valid_zip_codes.alameda.includes(zipcode)){
    valid_code = "Alameda";
    can_deliver = true;
  }else if(valid_zip_codes.oakland_ext.includes(zipcode)){
    valid_code = "Eastern Oakland";
    can_deliver = false;
  }else{
    valid_code = "No Delivery";
    can_deliver = false;
  }
  return {area:valid_code,can_deliver:can_deliver};
}