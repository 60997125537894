import React from 'react';
import './Home.css';
import InSeason from './InSeason.js';
import Order_Module from './Order_Module.js';
import About_Cards from './About_Overview.js';

//import hero_fruit from './img/hero-fruit.png';
import hero_fruit from './img/produce_bg2.png';

import how_order from './img/how_order.png';
import how_pick from './img/how_pick.png';
import how_deliver from './img/how_deliver.png';
import how_order_watercolor from './img/how_order_watercolor.png';
import how_pick_watercolor from './img/how_pick_watercolor.png';
import how_deliver_watercolor from './img/how_deliver_watercolor.png';
import order_bg from './img/order_bg.png';

import flexible_symbol from './img/flexible.png';
import local_symbol from './img/local.png';
import organic_symbol from './img/organic.png';
import low_emissions_symbol from './img/low_emissions.png';
import low_waste_symbol from './img/low_waste.png';


var fruit_season_data = {
  lemon1:{
    name:"Lemon",
    start:10,
    length:5
  },
  lemon2:{
    name:"Lemon",
    start:5,
    length:3
  },
  orange:{
    name:"Orange",
    start:10,
    length:6
  },
  blood_orange:{
    name:"Blood Orange",
    start:0,
    length:4
  },
  sweet_lemon:{
    name:"Sweet Lemon",
    start:11,
    length:4
  },
  mandarin:{
    name:"Mandarin",
    start:10,
    length:5
  },
  pomelo:{
    name:"Pomelo",
    start:0,
    length:4
  },
  grapefruit:{
    name:"Grapefruit",
    start:11,
    length:7
  },
  tangelo:{
    name:"Tangelo",
    start:1,
    length:3
  },
  loquat:{
    name:"Loquat",
    start:3,
    length:3
  },
  mulberry:{
    name:"Mulberry",
    start:3,
    length:3
  },
  strawberry:{
    name:"Strawberry",
    start:0,
    length:12
  },
  raspberry:{
    name:"Raspberry",
    start:4,
    length:8
  },
  golden_raspberry:{
    name:"Raspberry",
    sub_name:"Golden",
    start:5,
    length:5
  },
  blackberry:{
    name:"Blackberry",
    start:0,
    length:12
  },
  tayberry:{
    name:"Tayberry",
    start:5,
    length:3
  },
  cherry:{
    name:"Cherry",
    start:3,
    length:4
  },
  aprium:{
    name:"Aprium",
    start:4,
    length:2
  },
  apricot:{
    name:"Apricot",
    start:4,
    length:3
  },
  plum:{
    name:"Plum",
    start:5,
    length:5
  },
  pluot:{
    name:"Pluot",
    start:5,
    length:4
  },
  peach:{
    name:"Peach",
    start:4,
    length:6
  },
  nectarine:{
    name:"Nectarine",
    start:5,
    length:4
  },
  fig:{
    name:"Fig",
    start:5,
    length:5
  },
  jujube:{
    name:"Jujube",
    start:8,
    length:2
  },
  pear:{
    name:"Pear",
    start:7,
    length:4
  },
  asian_pear:{
    name:"Asian Pear",
    start:9,
    length:6
  },
  apple:{
    name:"Apple",
    start:6,
    length:9
  },
  dates:{
    name:"Dates",
    start:7,
    length:3
  },
  melon:{
    name:"Melon",
    start:6,
    length:4
  },
  grapes:{
    name:"Grapes",
    start:7,
    length:4
  },
  feijoas:{
    name:"Feijoas",
    start:9,
    length:3
  },
  persimmon:{
    name:"Persimmon",
    start:9,
    length:4
  },
  pomegranate:{
    name:"Pomegranate",
    start:9,
    length:4
  },
  white_pomegranate:{
    name:"Pomegranate",
    sub_name:"White",
    start:8,
    length:4
  },
  kiwi:{
    name:"Kiwi",
    start:1,
    length:4
  },
  avocado:{
    name:"Avocado",
    start:2,
    length:10
  },
  guava:{
    name:"Guava",
    start:0,
    length:3
  },
  cheimoya:{
    name:"Cherimoya",
    start:0,
    length:2
  },
  passionfruit:{
    name:"Passionfruit",
    start:2,
    length:8
  }
};


const date = new Date(); 
const date_prev = new Date(date.getFullYear(), date.getMonth()-1, 1);
const date_next1 = new Date(date.getFullYear(), date.getMonth()+1, 1);
const date_next2 = new Date(date.getFullYear(), date.getMonth()+2, 1);
const month_short = date.toLocaleString('default', { month: 'short' });
const month_long = date.toLocaleString('default', { month: 'long' });
const month_prev = date_prev.toLocaleString('default', { month: 'long' });
const month_next1 = date_next1.toLocaleString('default', { month: 'long' });
const month_next2 = date_next2.toLocaleString('default', { month: 'long' });


class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      current_carousel_step:0,
      previous_carousel_step:0,
      transition_class:""
    };
  }
  update_carousel(direction,e){
    var current_step = this.state.current_carousel_step;
    var farmer_array_length = Object.keys(this.props.farmer_data).length;
    if(direction=="right"){
      current_step++;
    }else if(direction=="left"){
      current_step--;
    }
    if(current_step < 0){
      current_step = farmer_array_length-1;
    }else if(current_step > farmer_array_length-1){
      current_step = 0
    }
    this.setState({
      transition_class:" faded"
    });
    setTimeout(() => {
      this.setState({
        current_carousel_step:current_step,
        transition_class:""
      });
    }, 400);
    
  }
  render_carousel(){

    var farmer_content = [];
    var farmer_images=this.props.farmer_images;
    var farmer_data=this.props.farmer_data;

    
    Object.keys(farmer_data).map((item, key) => {

      var transition_class="";
      
      if(this.state.current_carousel_step == key){
        transition_class=" active";
      }

      farmer_content.push(<div className={"farmer"+transition_class+this.state.transition_class}>
          <div className="col2 img_outer">
            <img src={farmer_images[farmer_data[item].img]}/>
          </div>
          <div className="col2 text_outer">
            <div className="farmer_info">
              <div className="name">{farmer_data[item].name}</div>
              <div className="description">{farmer_data[item].description}</div>
            </div>
          </div>
        </div>);

    });

    return farmer_content;

  }
  render() {

    /*
    <span className="word">FRUIT</span>
    <span className="word">VEGGIES</span>
    <span className="word">OLIVES</span>
    <span className="word">CHEESE</span>
    <span className="word">BUTTER</span>
    <span className="word">MILK</span>
    <span className="word">OLIVE OIL</span>
    <span className="word">SPIRULINA</span>
    <span className="word">FRUIT</span>
    */

    /*
    <div id="hero_maintext">
      <span className="one">
        LOCAL <span id="typewriter">
            <div id="type_inner">
              <span className="word">FRUIT</span>
              <span className="word">GREENS</span>
              <span className="word">OLIVES</span>
              <span className="word">CHEESE</span>
              <span className="word">MILK</span>
              <span className="word">FRUIT</span>
            </div>
          </span>
      </span><br/>
      <span className="two">DELIVERED</span><br/>
      <span className="three">BY BICYCLE</span>
    </div>
    */

    if(this.props.currentPage != "home"){
      return false;
    }
      
    return <section id="home" className="hidden">
      <div id="hero">
        <div className="page-container">
          <div id="hero_text">
            <div id="hero_maintext">
              <span className="one">
                SUSTAINABLE 
              </span><br/>
              <span className="two">
              <span id="typewriter">
                    <div id="type_inner">
                      <span className="word" style={{letterSpacing:".05em"}}>FRUIT</span>
                      <span className="word">GREENS</span>
                      <span className="word">OLIVES</span>
                      <span className="word">CHEESE</span>
                      <span className="word">BUTTER</span>
                      <span className="word" style={{letterSpacing:".05em"}}>FRUIT</span>
                    </div>
                  </span> DELIVERED
              </span><br/>
              <span className="three">SUSTAINABLY</span>
            </div>
            <div id="hero_subtext">EAST BAY</div>
          </div>
          <img id="hero_fruit" src={hero_fruit}/>
        </div>
      </div>
      <div id="how_it_works">
        <div className="page-container">
          <div className="section_title">
            <div className="title_text">HOW DOES IT WORK?</div>
            <div className="title_subtext upper">So,</div>
          </div>
          <div className="section_body col3">
            <div className="col">
              <img className="head_image" src={how_order}/>
              <img className="watercolor one" src={how_order_watercolor}/>
              <div className="column_body">
                <div className="title">1. Pick what you're interested in</div>
                <div className="description">Choose your order size, pay online, and cancel anytime. We keep the process as simple as possible.</div>
                <div className="list">
                  <div className="list_item">
                    <div className="title"><img src={flexible_symbol}/>Flexible</div>
                    <div className="description">Choose which day you want your delivery, customize what you do and don't want in your box.</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <img className="head_image" src={how_pick}/>
              <img className="watercolor two" src={how_pick_watercolor}/>
              <div className="column_body">
                <div className="title">2. We choose the best produce</div>
                <div className="description">Every week we pick out what we think is the best fruit & produce available from our partners at the SF farmer’s markets.</div>
                <div className="list">
                  <div className="list_item">
                    <div className="title"><img src={local_symbol}/>Local & Seasonal</div>
                    <div className="description">Our produce comes from California. Always.</div>
                  </div>
                  <div className="list_item">
                    <div className="title"><img src={organic_symbol}/>Quality-first</div>
                    <div className="description">We put quality and taste first. Quality also means we focus on how it is grown ~ we buy organic whenever possible.</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <img className="head_image" src={how_deliver}/>
              <img className="watercolor three" src={how_deliver_watercolor}/>
              <div className="column_body">
                <div className="title">3. We deliver to your door</div>
                <div className="description">Our team personally delivers your fruit to your home or office. <span id="covid">*We take precautions to avoid contact during Covid-19.</span></div>
                <div className="list">
                  <div className="list_item">
                    <div className="title"><img src={low_emissions_symbol}/>Low Emissions</div>
                    <div className="description">No long-distance shipping of products ever. Deliveries by bicycle, electric vehicle, or even by foot.</div>
                  </div>
                  <div className="list_item">
                    <div className="title"><img src={low_waste_symbol}/>Low Waste</div>
                    <div className="description">We deliver in re-usable delivery boxes that we pick up each week.</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Order_Module/>
      <InSeason/>
      <div id="farmers_hero">
        <div className="page-container">
        <div className="section_title">
            <div className="title_text">OUR FARMERS</div>
            <div className="title_subtext upper">Meet</div>
          </div>
          <div className="carousel_outer">
            <div className="left_arrow" onClick={this.update_carousel.bind(this,"left")}></div>
            <div className="right_arrow" onClick={this.update_carousel.bind(this,"right")}></div>
            {this.render_carousel()}
          </div>
        </div>
      </div>
      <About_Cards
        changePage={this.props.changePage.bind(this)}/>
    </section>;

  }
}


export default Home;

