import React from 'react';
import './About_Overview.css';

import watercolor_bg1 from './img/bg_watercolor/bg_watercolor_blue_transparent.png';
import watercolor_bg2 from './img/bg_watercolor/bg_watercolor_peach.png';
import watercolor_bg3 from './img/bg_watercolor/bg_watercolor_pink.png';

import watercolor_fruit1 from './img/bg_watercolor/bg_fruit_persimmon.png';
import watercolor_fruit2 from './img/bg_watercolor/bg_fruit_cherry.png';
import watercolor_fruit3 from './img/bg_watercolor/bg_fruit_fig_transparent.png';
import watercolor_fruit4 from './img/bg_watercolor/bg_watercolor_pomegranate.png';
import watercolor_fruit5 from './img/bg_watercolor/bg_fruit_melon.png';

import about_card_image1 from './img/bg_watercolor/about_card_image1.png';
import about_card_image2 from './img/bg_watercolor/about_card_image2.png';
import about_card_image3 from './img/bg_watercolor/about_card_image3.png';


class About_Cards extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
      
    return <section id="about_overview" className="hidden">
      <div className="page-container">
        <img src={watercolor_bg1} className="watercolor_bg one"/>
        <img src={watercolor_fruit1} className="watercolor_fruit one"/>
        <div className="about_card">
          <div className="title">Our Fruit</div>
          <div className="description">It’s no secret that California produces a sizable chunk of fruit, vegetables, nuts and dairy. Its temperate climate, matched with long seasons and rich soil give California an agricultural edge, providing the U.S. with nearly half of its produce. We’re pretty lucky to have access to fresh produce, and we’re even luckier that it’s the best in the nation (we may be biased, but the numbers don’t lie). 
<br/><br/>
The Bay Area serves as a bridge between Northern and Southern Californian agriculture. Things here aren’t too hot, or too cold, and our fruit is just right. So why is it that retailers still sell fruit and vegetables imported from far away regions?
<br/><br/>
Fruit by the Bay believes in keeping our fruit and vegetables local and fresh, which is why we source our produce from farmers local to California, and only ever deliver what is in season. We handpick our produce each week at local farmers markets in and around San Francisco, ensuring a variety of fresh berries, stone fruit, citrus, veggies, and snacks. 
</div>
          <div onClick={this.props.changePage.bind(this,"produce")} className="link">Learn More →</div>
        </div>
        <img src={about_card_image1} class="about_card_image one"/>
        <img src={watercolor_bg2} className="watercolor_bg two"/>
        <img src={watercolor_fruit2} className="watercolor_fruit two"/>
        <img src={watercolor_bg3} className="watercolor_bg three"/>
        <img src={watercolor_fruit3} className="watercolor_fruit three"/>
        <div className="about_card right">
          <div className="title">Our Delivery</div>
          <div className="description">What makes Fruit by the Bay special is not just the quality & local origin of our fruit, but it’s also the way that it gets from the farmer’s market to you. While some might have said there’s no way you could deliver 1 metric ton of fruit by bicycle, we've proven otherwise. Small eletric delivery vehicles? You bet. Our experimentation with no-emissions vehicles speaks to the hyper-local nature of our service, and to our dedication to keeping our service low-impact by reducing emissions everywhere possible.
          <br/><br/>
Our delivery service is pretty simple and involves just three steps: 
<br/>
<span className="list-item"><b>1.</b> Fruit pickup from the SF & East Bay farmer’s markets on Saturday & Sunday</span><br/>
<span className="list-item"><b>2.</b> Fruit sorting, where we divide up what we’ve bought into the delivery boxes for each of our customers.</span><br/>
<span className="list-item"><b>3.</b> Delivery to your door. When possible we deliver by bicycle, to keep emissions low.</span>
<br/><br/>
All of our deliveries are made using our completely reusable fruit crates. Every time we come by, we’ll pick up the previous empty crate, and drop off a new one, filled with your fruit & produce for the week.
</div>
          <div onClick={this.props.changePage.bind(this,"about")} className="link">Learn More →</div>
        </div>
        <img src={about_card_image2} class="about_card_image left two"/>
        <img src={watercolor_fruit4} className="watercolor_fruit four"/>
        <div className="about_card">
          <div className="title">Our Team</div>
          <div className="description">Fruit by the Bay’s founder, Christopher Panaiti leads the day-to-day operations. Christopher got his start in the fruit delivery business by working with and learning from Konstantin Kosov, the founder of <a href="www.spiritfruit.co" target="_BLANK">Spirit Fruit</a>, our sister company serving SF. Christopher spends every weekend out at the markets discoverings what's new, ripe, and delicious that week. 
          <br/><br/>
Shenandoah leads our efforts in Berkeley in addition to working on just about everything that helps get the fruit from the farmers to you. Plus, we're always looking for new partners to join our team. We run our fruit sorting operations out of our commercial kitchen space in Oakland. 
<br/><br/>
Fruit by the Bay takes inspiration from some of the other famous Bay Area co-ops and collectives like Arizmendi – we’re striving to build a team of co-workers and co-owners rather than just employees. Interested in helping out? Get in touch with our contact form at the bottom of the page.
</div>
          <div onClick={this.props.changePage.bind(this,"about")} className="link">Learn More →</div>
        </div>
        <img src={about_card_image3} class="about_card_image three"/>
        <img src={watercolor_bg1} className="watercolor_bg five"/>
        <img src={watercolor_fruit5} className="watercolor_fruit five"/>
      </div>
    </section>;

  }
}


export default About_Cards;

