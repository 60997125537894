import React from 'react';
import './Header.css';
import spirit_logo from './img/spirit_fruit_logo_revised.png';
import spirit_logo_text from './img/spirit_fruit_logo_text.png';
//import './App.css';

//var mira_logo_bg = {backgroundImage: `url(${mira_logo})`};

/*function Project() {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }
  return (
    <div className="Project-viewer">
      
    </div>
  );
}*/
/*
var ProjectViewer = React.createClass({
  propTypes: {
    // the StyledButton requires a clickHandler
    clickHandler: React.PropTypes.func.Required,
    // and I guess the text can be seen as required as well
    text: React.PropTypes.string.required
  },
  render: function() {
    // as you are sure you have a clickHandler, you can just reference it directly from the props
    return <button type="button" onClick={this.props.clickHandler}>{this.props.text}</button>;
  }
});*/

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      header_scrolled:false
    }
  }
  componentDidMount(){
    window.addEventListener('scroll', this.handleScroll.bind(this));
  }
  componentWillUnmount(){
    window.removeEventListener('scroll', this.handleScroll);
  }
  handleScroll(e){
    var scroll = window.scrollY || window.scrollTop || document.getElementsByTagName("html")[0].scrollTop;
    if(scroll > 50 && !this.state.header_scrolled){
      this.setState({
        header_scrolled:true
      });
    }else if(scroll <= 50 && this.state.header_scrolled){
      this.setState({
        header_scrolled:false
      });
    }
  }
  /*
  var nav_items = "";

    for(var i = 0; i < this.props.main_pages.length; i++){
      console.log(this.props.main_pages[i]);
      nav_items += (<div className="nav-item">{this.props.main_pages[i].Title}</div>);
    }

    Object.keys(this.state.elements).map((item, key) => {
      console.log(item);
      if(this.state.elements[item].type == type){
        num_of_type++;
      }
    });
  */

  render_nav_items(){
    

    var nav_items = Object.keys(this.props.main_pages).map((item, key) =>
      <div className={"nav-item"+(this.props.main_pages[item].Key==this.props.currentPage?" active":"")+(this.props.main_pages[item].Key=="contact"?" contact":"")} onClick={this.props.changePage.bind(this,this.props.main_pages[item].Key)}>{this.props.main_pages[item].Title}</div>
    );

    return (nav_items);
  }

  render() {
      
    return <section id="header" className="hidden">
      <div id="main_nav" data-scrolled={this.state.header_scrolled}>
        <div className="page-container">
          <div id="nav_inner">{this.render_nav_items()}</div>
        </div>
      </div>
      <div className="page-container">
        <div id="main_logo">
          <img id="logo_img" src={spirit_logo} />
          <div id="logo_text">
            <div id="logo_name">Fruit By The Bay</div>
            <div id="logo_tag">Farmer’s Market Produce Delivery</div>
          </div>
        </div>
      </div>
    </section>;

  }
}


export default Header;

