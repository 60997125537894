import React from 'react';
import './In_Season.css';
import grapefruit from './img/fruit_images/grapefruit.png';



const importAll = require =>
  require.keys().reduce((acc, next) => {
    acc[next.replace("./", "")] = require(next);
    return acc;
  }, {});



const fruit_images_small = importAll(
  require.context("./img/fruit_images/small", false, /\.(png|jpe?g|svg)$/)
);





var fruit_season_data = {
  lemon1:{
    name:"Lemon",
    start:10,
    length:5,
    img:"lemon.png"
  },
  lemon2:{
    name:"Lemon",
    start:5,
    length:3,
    img:"lemon.png"
  },
  orange:{
    name:"Orange",
    start:10,
    length:6,
    img:"grapefruit.png"
  },
  blood_orange:{
    name:"Blood Orange",
    start:0,
    length:4,
    img:"grapefruit.png"
  },
  sweet_lemon:{
    name:"Sweet Lemon",
    start:11,
    length:4,
    img:"lemon.png"
  },
  mandarin:{
    name:"Mandarin",
    start:10,
    length:5,
    img:"grapefruit.png"
  },
  pomelo:{
    name:"Pomelo",
    start:0,
    length:4,
    img:"grapefruit.png"
  },
  grapefruit:{
    name:"Grapefruit",
    start:11,
    length:7,
    img:"grapefruit.png"
  },
  tangelo:{
    name:"Tangelo",
    start:1,
    length:3,
    img:"grapefruit.png"
  },
  loquat:{
    name:"Loquat",
    start:3,
    length:3,
    img:"loquat.png"
  },
  mulberry:{
    name:"Mulberry",
    start:3,
    length:3,
    img:"mulberry.png"
  },
  strawberry:{
    name:"Strawberry",
    start:0,
    length:12,
    img:"strawberry.png"
  },
  raspberry:{
    name:"Raspberry",
    start:4,
    length:8,
    img:"raspberry.png"
  },
  golden_raspberry:{
    name:"Raspberry",
    sub_name:"Golden",
    start:5,
    length:5,
    img:"golden_raspberry.png"
  },
  blackberry:{
    name:"Blackberry",
    start:0,
    length:12,
    img:"blackberry.png"
  },
  tayberry:{
    name:"Tayberry",
    start:5,
    length:3,
    img:"tayberry.png"
  },
  cherry:{
    name:"Cherry",
    start:3,
    length:4,
    img:"cherry.png"
  },
  aprium:{
    name:"Aprium",
    start:4,
    length:2,
    img:"aprium.png"
  },
  apricot:{
    name:"Apricot",
    start:4,
    length:3,
    img:"apricot.png"
  },
  plum:{
    name:"Plum",
    start:5,
    length:5,
    img:"plum.png"
  },
  pluot:{
    name:"Pluot",
    start:5,
    length:4,
    img:"pluot.png"
  },
  peach:{
    name:"Peach",
    start:4,
    length:6,
    img:"peach.png"
  },
  nectarine:{
    name:"Nectarine",
    start:5,
    length:4,
    img:"nectarine.png"
  },
  fig:{
    name:"Fig",
    start:5,
    length:5,
    img:"fig.png"
  },
  jujube:{
    name:"Jujube",
    start:8,
    length:2,
    img:"grapefruit.png"
  },
  pear:{
    name:"Pear",
    start:7,
    length:4,
    img:"pear.png"
  },
  asian_pear:{
    name:"Asian Pear",
    start:9,
    length:6,
    img:"asian_pear.png"
  },
  apple:{
    name:"Apple",
    start:6,
    length:9,
    img:"apple.png"
  },
  dates:{
    name:"Dates",
    start:7,
    length:3,
    img:"dates.png"
  },
  melon:{
    name:"Melon",
    start:6,
    length:4,
    img:"melon.png"
  },
  grapes:{
    name:"Grapes",
    start:7,
    length:4,
    img:"grapes.png"
  },
  feijoas:{
    name:"Feijoas",
    start:9,
    length:3,
    img:"feijoas.png"
  },
  persimmon:{
    name:"Persimmon",
    start:9,
    length:4,
    img:"persimmon.png"
  },
  pomegranate:{
    name:"Pomegranate",
    start:9,
    length:4,
    img:"pomegranate.png"
  },
  white_pomegranate:{
    name:"Pomegranate",
    sub_name:"White",
    start:8,
    length:4,
    img:"white_pomegranate.png"
  },
  kiwi:{
    name:"Kiwi",
    start:1,
    length:4,
    img:"kiwi.png"
  },
  avocado:{
    name:"Avocado",
    start:2,
    length:10,
    img:"avocado.png"
  },
  guava:{
    name:"Guava",
    start:0,
    length:3,
    img:"grapefruit.png"
  },
  cheimoya:{
    name:"Cherimoya",
    start:0,
    length:2,
    img:"grapefruit.png"
  },
  passionfruit:{
    name:"Passionfruit",
    start:2,
    length:8,
    img:"passionfruit.png"
  }
};


const date = new Date(); 
const date_prev = new Date(date.getFullYear(), date.getMonth()-1, 1);
const date_next1 = new Date(date.getFullYear(), date.getMonth()+1, 1);
const date_next2 = new Date(date.getFullYear(), date.getMonth()+2, 1);
const month_short = date.toLocaleString('default', { month: 'short' })+".";
const month_long = date.toLocaleString('default', { month: 'long' });
const month_prev = date_prev.toLocaleString('default', { month: 'long' });
const month_next1 = date_next1.toLocaleString('default', { month: 'long' });
const month_next2 = date_next2.toLocaleString('default', { month: 'long' });


class InSeason extends React.Component {
  constructor(props) {
    super(props);
  }
  render_active_bars(){
    
    
    var season_bars = [];
    Object.keys(fruit_season_data).map((item, key) => {

      var this_month = date.getMonth();

      var start = fruit_season_data[item].start;
      var length = fruit_season_data[item].length;
      var end = start + length;


      if(this_month < start){
        //if the start month for this fruit is > current month, lower all points by 12
        start -= 12;
        end -= 12;
      }

      if(this_month <= end-1){
        //fruit is in season
        
        start = (start - this_month+1 < 0?0:start - this_month+1);
        end = (end - this_month+1 > 2?2:end - this_month+1);
        length = end-start+1;
        if(fruit_season_data[item].length == 12){
          start = 0;
          end = 2;
          length = 3;
        }
        
        season_bars.push(<div className="bar" data-start={start} data-length={length} data-end={end}></div>);
        console.log(season_bars);

      }
        
    });
    return season_bars;
  }
  render_bars(){
    
    
    var season_bars = [];
    Object.keys(fruit_season_data).map((item, key) => {

      var this_month = date.getMonth();

      var start = fruit_season_data[item].start;
      var length = fruit_season_data[item].length;
      var end = start + length;


      if(this_month < start){
        //if the start month for this fruit is > current month, lower all points by 12
        start -= 12;
        end -= 12;
      }

      if(this_month <= end-1){
        //fruit is in season
        
        start = (start - this_month+2 < 0?0:start - this_month+2);
        end = (end - this_month+1 > 5?5:end - this_month+1);
        length = end-start+1;
        if(fruit_season_data[item].length == 12){
          start = 0;
          end = 5;
          length = 5;
        }
        
        season_bars.push(<div className="bar" data-start={start} data-length={length} data-end={end}></div>);
        console.log(season_bars);

      }
        
    });
    return season_bars;
  }
  render_labels(){

    var season_labels = [];
    Object.keys(fruit_season_data).map((item, key) => {

      var start = fruit_season_data[item].start;
      var length = fruit_season_data[item].length;
      var end = start + length;

      if(date.getMonth() < start){
        start = start - 12;
        end = end - 12;
      }

      if(date.getMonth() <= end-1){
        //fruit is in season

        season_labels.push(<div className="label"><div className="sub_label">{fruit_season_data[item].sub_name}</div><img src={fruit_images_small[fruit_season_data[item].img]}/>{fruit_season_data[item].name}</div>);

      }
        
    });
    return season_labels;
    /*
    var season_bars = Object.keys(fruit_season_data).map((item, key) =>
    <div className="label"><img src={fruit_images_small[fruit_season_data[item].img]}/>{fruit_season_data[item].name}</div>
    );
    return season_bars;*/
  }
  render() {
      
    return <div id="in_season" className="hidden">
        <div className="page-container">
          <div className="section_title">
            <div className="title_text">WHAT'S IN SEASON?</div>
            <div className="title_subtext upper">{month_short}</div>
          </div>
          <div className="toggle_tab">
            <div className="tab active">FRUIT</div>
            <div className="tab">GREENS</div>
            <div className="tab">DAIRY</div>
            <div className="dot one"></div>
            <div className="dot two"></div>
          </div>
          <div className="intro_text">We only deliver what’s in season & our fruit is never imported. Check out what's in season this month!​ </div>
          <div id="seasonal_chart">
            <div className="chart_labels">
              {this.render_labels()}
            </div>
            <div className="chart_bars">
              {this.render_bars()}
            </div>
            <div className="chart_columns">
              <div className="chart_active_bars">
                {this.render_active_bars()}
              </div>
              <div className="chart_column active">
                <div className="label">{month_prev}</div>
              </div>
              <div className="chart_column active">
                <div className="label active">{month_long}</div>
              </div>
              <div className="chart_column">
                <div className="label">{month_next1}</div>
              </div>
              <div className="chart_column">
                <div className="label">{month_next2}</div>
              </div>
            </div>
          </div>
        </div>
    </div>;

  }
}


export default InSeason;

