import React from 'react';
import firebase from 'firebase';
import config from './Config';
import './Order_Module.css';
//import order_bg from './img/order_bg.png';
import order_bg from './img/order_bg_2.jpg';
import back_button from './img/back_button.png';
import box_options_img from './img/fruit_boxes/box_options.png';
import size_options_img from './img/fruit_boxes/size_options.png';
import box1 from './img/fruit_boxes/box1.png';
import box2 from './img/fruit_boxes/box2.png';
import box3 from './img/fruit_boxes/box3.png';
//import mira_logo from './img/mira-logo-mini.png';



//////// FIREBASE DATABASE /////////
/*
var config = {
  apiKey: "AIzaSyD5-WNCbGm2PDY4o8Kylj2380Quud6SULI",
  authDomain: "spirit-fruit.firebaseapp.com",
  databaseURL: "https://spirit-fruit.firebaseio.com",
  storageBucket: "spirit-fruit.appspot.com"
};
firebase.initializeApp(config);*/




// Your web app's Firebase configuration
var firebaseConfig_OLD = {
  apiKey: "AIzaSyD5-WNCbGm2PDY4o8Kylj2380Quud6SULI",
  authDomain: "spirit-fruit.firebaseapp.com",
  databaseURL: "https://spirit-fruit.firebaseio.com",
  projectId: "spirit-fruit",
  storageBucket: "spirit-fruit.appspot.com",
  messagingSenderId: "294433306114",
  appId: "1:294433306114:web:3f5264f4ead16e7296d91b",
  measurementId: "G-81S2DHH7T6"
};

var firebaseConfig = {
  apiKey: "AIzaSyBJRyk7COlxwMBz3JIok4tn27HgehAf1UE",
  authDomain: "spirit-fruit-website.firebaseapp.com",
  databaseURL: "https://spirit-fruit-website.firebaseio.com",
  projectId: "spirit-fruit-website",
  storageBucket: "spirit-fruit-website.appspot.com",
  messagingSenderId: "851046976931",
  appId: "1:851046976931:web:ee17282c93b79b2662de16",
  measurementId: "G-W781ZV3TEY"
};

var firebaseConfig_EB = {
  apiKey: "AIzaSyAtIjM2EeH5UDXGoDGXfTMGZETaOPaOVNs",
  authDomain: "fruitbythebay.firebaseapp.com",
  databaseURL: "https://fruitbythebay.firebaseio.com",
  projectId: "fruitbythebay",
  storageBucket: "fruitbythebay.appspot.com",
  messagingSenderId: "328916183748",
  appId: "1:328916183748:web:101e438893753c85253791",
  measurementId: "G-FMR2KNNVC8"
};






// Get a reference to the database service
//var database = firebase.database();






var valid_zip_codes = {
  sf:["94102","94103","94105","94107","94108","94109","94110","94111","94112","94114","94115","94116","94117","94118","94121","94122","94123","94124","94127","94129","94131","94132","94133","94134","94158"],
  oakland:["94601","94602","94603","94605","94606","94607","94608","94609","94610","94611","94612","94613","94618","94619","94621"],
  oakland_ext:["94619","94621","94605"],
  berkeley:["94702","94703","94704","94705","94706","94707","94708","94709","94710","94720"],
  alameda:["94501","94502"],
  treasure_island:["94130"]
};

var zip_code_responses = {
  "San Francisco":"✅ Great! We deliver to your area of San Francisco. Our deliveries usually happen Sunday-Wednesday and you'll be able to let us know your delivery preferences when you place your order.",
  "Treasure Island":"💔 At the moment we're not currently delivering to Treasure Island, but we'd like to! Contact us at the bottom of the page if you're interested in a delivery to your Treasure Island address.",
  "Oakland":"✅ Great! We deliver to your area of Oakland. Our deliveries usually happen Sunday-Wednesday and you'll be able to let us know your delivery preferences when you place your order.",
  "Eastern Oakland":"💔 At the moment we're not currently delivering to your area in Oakland, but we'd like to! Contact us at the bottom of the page if you're interested in a delivery to your Oakland address.",
  "Alameda":"✅ Great! We deliver to your area of Alameda. Our deliveries usually happen Sunday-Wednesday and you'll be able to let us know your delivery preferences when you place your order",
  "Berkeley":"✅ Great! We deliver to your area of Berkeley. Our deliveries usually happen Sunday-Wednesday and you'll be able to let us know your delivery preferences when you place your order",
  "No Delivery":"💔At the moment we're not currently delivering to your area. If you'd like more information, send us a message using the form at the bottom of the page."
}

var box_options = {
  fruit:{
    value:"fruit",
    title:"Fruit",
    subtitle:"Market Fresh",
    popular:true
  },
  veggies:{
    value:"veggies",
    title:"Greens",
    subtitle:"Top Produce"
  },
  spirulina:{
    value:"spirulina",
    title:"Spirulina",
    subtitle:"Local & Fresh"
  },
  milk:{
    value:"milk",
    title:"Milk",
    subtitle:"Raw & Unpasturized"
  },
  cream:{
    value:"cream",
    title:"Cream",
    subtitle:"Raw & Unpasturized"
  },
  cheese:{
    value:"cheese",
    title:"Cheese",
    subtitle:"Raw & Unpasturized"
  },
  butter:{
    value:"butter",
    title:"Butter",
    subtitle:"Raw & Unpasturized"
  },
  yogurt:{
    value:"yogurt",
    title:"Yogurt",
    subtitle:"Organic"
  },
  olive_oil:{
    value:"olive_oil",
    title:"Olive Oil",
    subtitle:"Top Quality"
  },
  olives:{
    value:"olives",
    title:"Olives",
    subtitle:"Delicious"
  },
  dried_fruit:{
    value:"dried_fruit",
    title:"Dried Fruit",
    subtitle:"Grab & Go"
  },
  juice:{
    value:"juice",
    title:"Juice",
    subtitle:"Fresh Squeezed"
  },
  eggs:{
    value:"eggs",
    title:"Eggs",
    subtitle:"Local"
  }
};

var delivery_days = {
  Sunday:{
    value:"Sunday",
    title:"Sunday",
    subtitle:"Pickup Day"
  },
  Monday:{
    value:"Monday",
    title:"Monday",
    subtitle:""
  },
  Wednesday:{
    value:"Wednesday",
    title:"Wednesday",
    subtitle:"Pickup Day"
  },
};


var box_sizes = [35,50,75,100,150,200,300];




var people_options_table = {
  "Business":{
    "1":"1-5",
    "2":"5-10",
    "3":"10-15",
    "4":"15-20",
    "5":"20-30",
    "6":"30-50",
    "7":"50-100",
    "9":"100+"
  },
  "Residential":{
    "1":"1-3",
    "2":"4-6",
    "3":"7-10",
    "4":"10-15",
    "5":"15-20",
    "6":"20-30",
    "7":"30+"
  }
};





function calculate_recommended_box_size(order_type,num_people){

  var recommended_size=50;

  if(order_type == "Business"){
    switch (num_people) {
      case "1":
        recommended_size = 35;
        break;
      case "2":
        recommended_size = 50;
        break;
      case "3":
        recommended_size = 75;
        break;
      case "4":
        recommended_size = 100;
        break;
      case "5":
        recommended_size = 150;
        break;
      case "6":
        recommended_size = 200;
        break;
      case "7":
        recommended_size = 300;
        break;
        case "8":
          recommended_size = 300;
          break;
      default:
        recommended_size = 50;
    }
  }else if(order_type == "Residential"){
    switch (num_people) {
      case "1":
        recommended_size = 35;
        break;
      case "2":
        recommended_size = 50;
        break;
      case "3":
        recommended_size = 75;
        break;
      case "4":
        recommended_size = 100;
        break;
      case "5":
        recommended_size = 150;
        break;
      case "6":
        recommended_size = 200;
        break;
      case "7":
        recommended_size = 300;
        break;
      case "8":
        recommended_size = 300;
        break;
      default:
        recommended_size = 50;
    }
  }
  return recommended_size;
}



var paypal_links_konstantin = {
  P35:"https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-38B4162744349823FL3ZJDVA",
  P50:"https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-81T43850GN871891KL3ZJDGQ",
  P75:"https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-5AP56307AA592935DL3ZJCTY",
  P100:"https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-6HW98588NF316953SL3ZJCGI",
  P150:"https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-2XR20640LR145054AL3ZJBWY",
  P200:"https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-4SU50485VA993060LL3ZJBFI",
  P250:"https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-91J14677SW407540PL3ZJARQ",
  P300:"https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-57941118BY692612RL3ZI7LQ"
}

var paypal_links_konstantin_10_OFF = {
  P35:"https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-2XK957263U4716140L32EAWI",
  P50:"https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-3CC984504A8003702L32EBKI",
  P75:"https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-8AJ250718A565070DL32EBZA",
  P100:"https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-59B96744KJ319503AL32ECII",
  P150:"https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-2XR20640LR145054AL3ZJBWY",
  P200:"https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-4SU50485VA993060LL3ZJBFI",
  P250:"https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-91J14677SW407540PL3ZJARQ",
  P300:"https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-57941118BY692612RL3ZI7LQ"
}

var paypal_links_konstantin_50_OFF = {
  P35:"https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-37N42093LY507741FL32D4MY",
  P50:"https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-6DT52507A9327632RL32D5QI",
  P75:"https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-73L917278N4063648L32D6KA",
  P100:"https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-7AU08681J7988893SL32D7BA",
  P150:"https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-2XR20640LR145054AL3ZJBWY",
  P200:"https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-4SU50485VA993060LL3ZJBFI",
  P250:"https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-91J14677SW407540PL3ZJARQ",
  P300:"https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-57941118BY692612RL3ZI7LQ"
}


/*
      <br/><br/>
          <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
<input type="hidden" name="cmd" value="_s-xclick"/>
<input type="hidden" name="hosted_button_id" value="USCMB7LKH4WYG"/>
<input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_subscribeCC_LG.gif" border="0" name="submit" alt="PayPal - The safer, easier way to pay online!"/>
<img alt="" border="0" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1"/>
</form>
*/


function check_for_valid_promo(promo_code){
  var cap_promo = promo_code.toUpperCase();
  var promo_valid = false;
  switch (cap_promo) {
    case "J10SF":
      promo_valid = "$10 OFF";
      break;
    case "GET10":
      promo_valid = "$10 OFF";
      break;
    case "J50EB":
      promo_valid = "50% OFF";
      break;
    case "1HALF":
      promo_valid = "50% OFF";
      break;
    /*case "10OFF":
      promo_valid = "$10 OFF";
      break;*/
    default:
      promo_valid = false;
  }
  return promo_valid;
}


var promo_code_responses = {
  "J10SF":"Your promo for $10 off your first box is automatically applied when you pay with Paypal.",
  "GET10":"Your promo for $10 off your first box is automatically applied when you pay with Paypal.",
  "1FREE":"Your promo for 100% off your first box is automatically applied when you pay with Paypal.",
  "1HALF":"Your promo for 50% off your first box is automatically applied when you pay with Paypal.",
  "10OFF":"Your promo for $10 off your first box is automatically applied when you pay with Paypal.",
  "J50EB":"Your promo for 50% off your first box is automatically applied when you pay with Paypal."
}


class Order_Module extends React.Component {
  constructor(props) {
    super(props);
    this.state={
      current_order_step:0,
      current_order_type:false,
      previous_order_type:false,
      order_module_faded:false,
      valid_zip_area:false,
      can_deliver_here:false,
      paypal_rendered:false,
      user_inputs:{
        zip_code:"",
        num_people:"",
        box_options:{
          fruit:true,
        },
        delivery_days:{},
        box_size:"",
        name:"",
        email:"",
        phone:"",
        street_address:"",
        delivery_instructions:"",
        special_requests:"",
        promo_code:"",
        valid_promo:false
      }
    };
  }
  componentDidUpdate(){
    /*
    if(this.state.current_order_step == 7 && !this.state.paypal_rendered && this.state.valid_zip_area != 'San Francisco'){
      this.setState({
        paypal_rendered:true
      },function(){
        window.render_paypal('#paypal-button-container');
      });
    }else if(this.state.current_order_step != 7 && this.state.paypal_rendered && this.state.valid_zip_area != 'San Francisco'){
      this.setState({
        paypal_rendered:false
      });
    }*/
  }
  add_order_to_firebase() {

    

    // A post entry.
    var user_data = {...this.state.user_inputs};

    if(this.state.valid_zip_area=="Berkeley" || this.state.valid_zip_area=="Oakland" || this.state.valid_zip_area=="Alameda"){
      // Initialize Firebase
      firebase.initializeApp(firebaseConfig_EB);
      firebase.analytics();
    }else{
      // Initialize Firebase
      firebase.initializeApp(firebaseConfig);
      firebase.analytics();
    }

    user_data.order_type=this.state.current_order_type;
    user_data.zip_area=this.state.valid_zip_area;
    user_data.email=user_data.email.toLowerCase();
    user_data.phone=user_data.phone.replace(/\D/g,'');
    user_data.num_people=people_options_table[this.state.current_order_type][this.state.user_inputs.num_people];
    user_data.box_options_list=Object.keys(user_data.box_options).join(", ");
    user_data.created_timestamp = Date.now();
    
    var this_paypal_links = paypal_links;
    if(user_data.valid_promo!=false){
      user_data.promo_code=user_data.promo_code.toUpperCase();
      user_data.valid_promo=user_data.valid_promo;
      user_data.promo_response = promo_code_responses[this.state.user_inputs.promo_code.toUpperCase()];

      if(user_data.zip_area == "San Francisco"){
        this_paypal_links = paypal_links_konstantin;
        if(user_data.promo_code=="J10SF"){
          this_paypal_links = paypal_links_konstantin_10_OFF;
        }else if(user_data.promo_code=="GET10"){
          this_paypal_links = paypal_links_konstantin_10_OFF;
        }else if(user_data.promo_code=="J50EB"){
          this_paypal_links = paypal_links_konstantin_50_OFF;
        }else if(user_data.promo_code=="1HALF"){
          this_paypal_links = paypal_links_konstantin_50_OFF;
        }
      }else{
        if(user_data.promo_code=="J10SF"){
          this_paypal_links = paypal_links_10_OFF;
        }else if(user_data.promo_code=="GET10"){
          this_paypal_links = paypal_links_10_OFF;
        }else if(user_data.promo_code=="J50EB"){
          this_paypal_links = paypal_links_50_OFF;
        }else if(user_data.promo_code=="1HALF"){
          this_paypal_links = paypal_links_50_OFF;
        }
      }

    }

    
    

    user_data.paypal_link = this_paypal_links[String("P"+user_data.box_size)];
    user_data.created_date = (new Date()).toISOString().split('T')[0];
    var this_box_options = Object.keys(user_data.box_options);
    for(var i=0;i<this_box_options.length; i++){
      user_data.box_options[this_box_options[i]] = this_box_options[i];
    }
    var this_delivery_days = Object.keys(user_data.delivery_days);
    user_data.delivery_days_obj = {};
    for(var i=0;i<this_delivery_days.length; i++){
      user_data.delivery_days_obj[this_delivery_days[i]] = this_delivery_days[i];
    }
    user_data.delivery_days=Object.keys(user_data.delivery_days).join(", ");
    console.log(user_data);
  


    // Get a key for a new Post.
    var newPostKey = firebase.database().ref().child('customers').push().key;

    // Write the new post's data simultaneously in the posts list and the user's post list.
    var updates = {};
    updates['/customers/' + newPostKey] = user_data;
    
    firebase.database().ref().update(updates);
    
    /*
    if(user_data.zip_area == "Berkeley" || user_data.zip_area == "Oakland" || user_data.zip_area == "Alameda"){
      
      var newPostKeyEAST = firebase.database().ref().child('customers_EAST').push().key;

      var updatesEAST = {};
      updatesEAST['/customers_EAST/' + newPostKey] = user_data;

      firebase.database().ref().update(updatesEAST);

    }else if(user_data.zip_area == "San Francisco"){

      // Get a key for a new Post.
      var newPostKeySF = firebase.database().ref().child('customers_SF').push().key;

      // Write the new post's data simultaneously in the posts list and the user's post list.
      var updatesSF = {};
      updatesSF['/customers_SF/' + newPostKey] = user_data;
      
      firebase.database().ref().update(updates);

    }*/

    
    
    //var user_reference = {customer_id:newPostKey}
  
    

    
    //updates['/emails/' + user_data.email] = user_reference;

    


    this.reset_order_module(true);
    
    //return firebase.database().ref().update(updates);
  }
  fade_order_module(direction){
    if(direction == "out"){
      this.setState({
        order_module_faded:true
      });
    }else if(direction == "in"){
      this.setState({
        order_module_faded:false
      });
    }
  }
  start_order(order_type){
    if(this.state.current_order_type != order_type && this.state.previous_order_type == order_type){
      this.fade_order_module("out");
      setTimeout(() => {
        this.setState({
          current_order_step:1,
          current_order_type:order_type
        },function(){
          this.fade_order_module("in");
        }.bind(this));
      }, 500);
    }else if(this.state.current_order_type != order_type && this.state.previous_order_type != order_type){
      this.fade_order_module("out");
      setTimeout(() => {
        this.setState({
          current_order_step:1,
          current_order_type:order_type,
          previous_order_type:false,
          order_module_faded:false,
          valid_zip_area:this.state.valid_zip_area,
          can_deliver_here:this.state.can_deliver_here,
          paypal_rendered:false,
          user_inputs:{
            zip_code:this.state.user_inputs.zip_code,
            num_people:"",
            box_options:{
              fruit:true,
            },
            box_size:"",
            delivery_days:{},
            name:"",
            email:"",
            phone:"",
            street_address:"",
            delivery_instructions:"",
            special_requests:"",
            promo_code:"",
            valid_promo:false
          }
        },function(){
          this.fade_order_module("in");
        }.bind(this));
      }, 500);
    }
    if("get_current_order_type" in this.props){
      this.props.get_current_order_type(order_type);
    }
  }
  reset_order_module(order_finished){
    var current_step = 0;
    if(order_finished){
      current_step = "COMPLETE"
    }
    this.fade_order_module("out");
    setTimeout(() => {
      this.setState({
        current_order_step:current_step,
        current_order_type:false,
        previous_order_type:false,
        order_module_faded:false,
        valid_zip_area:false,
        can_deliver_here:false,
        paypal_rendered:false,
        user_inputs:{
          zip_code:"",
          num_people:"",
          box_options:{
            fruit:true,
          },
          box_size:"",
          delivery_days:{},
          name:"",
          email:"",
          phone:"",
          street_address:"",
          delivery_instructions:"",
          special_requests:"",
          promo_code:"",
          valid_promo:false
        }
      },function(){
        this.fade_order_module("in");
      }.bind(this));
    }, 500);
  }
  close_completed_order(){
    this.fade_order_module("out");
    setTimeout(() => {
      this.setState({
        current_order_step:0
      },function(){
        this.fade_order_module("in");
      }.bind(this));
    }, 500);
  }
  next_order_step(){
    if(this.state.current_order_step > 7 || this.state.order_module_faded){
      return false;
    }
    this.fade_order_module("out");
    setTimeout(() => {
      this.setState({
        current_order_step:this.state.current_order_step+1
      },function(){
        this.fade_order_module("in");
      }.bind(this));
    }, 500);
  }
  previous_order_step(){
    this.fade_order_module("out");
    setTimeout(() => {
      if(this.state.current_order_step > 1){
        this.setState({
          current_order_step:this.state.current_order_step-1
        },function(){
          this.fade_order_module("in");
        }.bind(this));
      }else{
        this.setState({
          current_order_step:0,
          current_order_type:false,
          previous_order_type:this.state.current_order_type
        },function(){
          this.fade_order_module("in");
          if("get_current_order_type" in this.props){
            this.props.get_current_order_type(false);
          }
        }.bind(this));
      }
    }, 500);
  }
  handleEnterPress(e){
    if (e.key === "Enter") {
      e.preventDefault();
      if(this.state.current_order_step==1 && this.state.can_deliver_here){
        this.next_order_step();
      }else if(this.state.current_order_step==5 && is_valid_phone(this.state.user_inputs.phone) && is_valid_email(this.state.user_inputs.email)){
        this.next_order_step();
      }
    }
  }
  confirm_step(e){
    if(this.state.current_order_step==1){
      
      
      var input_text = e.target.value;
      if(input_text.length > 5){
        return false;
      }

      var user_inputs = this.state.user_inputs;

      var can_deliver_here = false;
      var approved_zip_area = false;
      var valid_zip = false;
      if(is_valid_zipcode(input_text)){
        var valid_zip = is_approved_zipcode(input_text);
        if(valid_zip.can_deliver){
          can_deliver_here = true;
          approved_zip_area = valid_zip.area;
        }else{
          approved_zip_area = valid_zip.area;
        }
      }

      user_inputs.zip_code = input_text;
      
      this.setState({
        user_inputs:user_inputs,
        can_deliver_here:can_deliver_here,
        valid_zip_area:approved_zip_area
      });
      
    }else if(this.state.current_order_step==2){

      var input_text = e.target.value;
      var user_inputs = this.state.user_inputs;
      user_inputs.num_people = input_text;

      this.setState({
        user_inputs:user_inputs
      });
    }else if(this.state.current_order_step==3){

      var input_text = e.currentTarget.getAttribute("data-value");
      var user_inputs = this.state.user_inputs;
      if(this.state.user_inputs.box_options[input_text] == true){
        delete this.state.user_inputs.box_options[input_text];
      }else{
        user_inputs.box_options[input_text] = true;
      }
      
      
      this.setState({
        user_inputs:user_inputs
      });
    }else if(this.state.current_order_step==4){

      var input_text = e.currentTarget.getAttribute("data-value");
      var user_inputs = this.state.user_inputs;
      if(this.state.user_inputs.box_size == input_text){
        user_inputs.box_size = "";
      }else{
        user_inputs.box_size = input_text;
      }
      
      
      this.setState({
        user_inputs:user_inputs
      });
    }else if(this.state.current_order_step==5){

      var input_text = e.currentTarget.value;
      var input = e.currentTarget.getAttribute("data-input");
      
      var user_inputs = this.state.user_inputs;
      if(input == "phone"){
        user_inputs.phone = input_text;
      }else if(input == "email"){
        user_inputs.email = input_text;
      }else if(input == "name"){
        user_inputs.name = input_text;
      }

      this.setState({
        user_inputs:user_inputs
      });
    }else if(this.state.current_order_step==6){

      var input_text = e.currentTarget.value;
      var input = e.currentTarget.getAttribute("data-input");
      
      var user_inputs = this.state.user_inputs;
      if(input == "street_address"){
        user_inputs.street_address = input_text;
      }else if(input == "delivery_instructions"){
        user_inputs.delivery_instructions = input_text;
      }else if(input == "special_requests"){
        user_inputs.special_requests = input_text;
      }
      

      this.setState({
        user_inputs:user_inputs
      });
    }else if(this.state.current_order_step==7){

      var input_text = e.currentTarget.getAttribute("data-value");
      var user_inputs = this.state.user_inputs;
      if(this.state.user_inputs.delivery_days[input_text] == true){
        delete this.state.user_inputs.delivery_days[input_text];
      }else{
        user_inputs.delivery_days[input_text] = true;
      }
      
      
      this.setState({
        user_inputs:user_inputs
      });
    }else if(this.state.current_order_step==8){

      var input = e.currentTarget.getAttribute("data-input");
      
      var user_inputs = this.state.user_inputs;

      if(input == "promo_code"){

        var input_promo = e.currentTarget.value;
        var user_inputs = this.state.user_inputs;
        user_inputs.promo_code = input_promo;
        user_inputs.valid_promo = check_for_valid_promo(input_promo);

      }else if(input == "checkbox"){

        var input_checked = e.currentTarget.checked;
        var user_inputs = this.state.user_inputs;
        user_inputs.agreement = input_checked;

      }

      

      this.setState({
        user_inputs:user_inputs
      });
    }
  }
  render_order_module(){

    if(this.state.current_order_step == 0){
      return (<div className="page-container">
        <div id="residential_module" className="start_order_module" data-faded={this.state.order_module_faded}>
          <div className="title">Residential Delivery</div>
          <div className="description">We’re bringing the best the bay has to offer right to your door. Get ready for the highest quality fruit, greens, and cheeses, all from local california farms and crafters.</div>
          <div className="next" onClick={this.start_order.bind(this,"Residential")}>Get Started →</div>
        </div>
        <div id="business_module" className="start_order_module" data-faded={this.state.order_module_faded}>
          <div className="title">Business Delivery</div>
          <div className="description">Your team deserves the best. Our business box will take your workplace to the next level with fruit and snacks that are nutritious, delicious, and always local. </div>
          <div className="next" onClick={this.start_order.bind(this, "Business")}>Get Started →</div>
        </div>
      </div>);
    }else if(this.state.current_order_step == 1){

      var description = "Our service is local and can only deliver to certain areas. Let’s make sure you’re in our delivery zone before we get any further.";
      if(this.state.valid_zip_area!=false){
        description=zip_code_responses[this.state.valid_zip_area];
      }
      
      return (<div className="page-container">
        <div id="zip_code_module" className="order_module small" data-faded={this.state.order_module_faded}>
          <img src={back_button} onClick={this.previous_order_step.bind(this)} className="back_button"/>
          <div className="question">Which zip code are we delivering to?</div>
          <input autocomplete="on" value={this.state.user_inputs.zip_code} data-approved={this.state.can_deliver_here} type="text" className="main_input" name="zipcode" placeholder="94107" onKeyPress={e => this.handleEnterPress(e)} onChange={e => this.confirm_step(e)}/>
          <div className="description">{description}</div>
          <div className={"next"+(this.state.can_deliver_here?"":" disabled")}  onClick={this.next_order_step.bind(this)}>Next →</div>
        </div>
      </div>);
    }else if(this.state.current_order_step == 2){
      
      var description = (<div className="description">We’ll let you know how much food we recommend for the number of people you’re feeding. This helps keep food from going to waste!</div>);
      if(this.state.user_inputs.num_people!=""){
        description=(<div className="description"><span className="icon">✅</span> Great! We've got the perfect box for you.</div>);
      }

      var question = '';
      var people_options = '';
      if(this.state.current_order_type == "Business"){
        question = 'How many employees are you feeding?';
         people_options = people_options_table["Business"];
      }else if(this.state.current_order_type == "Residential"){
        question = 'How many people are in your household?';
        people_options = people_options_table["Residential"];
      }


      var render_people_select = [];
      Object.keys(people_options).map((item, key) => {
        render_people_select.push(<option value={item}>{people_options[item]}</option>);
      });
      



      return (<div className="page-container">
        <div id="num_people_module" className="order_module small" data-faded={this.state.order_module_faded}>
          <img src={back_button} onClick={this.previous_order_step.bind(this)} className="back_button"/>
          <div className="question">{question}</div>
          <select value={this.state.user_inputs.num_people} className="main_input" onChange={e => this.confirm_step(e)}>
            <option value="" disabled selected>Number of people</option>
            {render_people_select}
          </select>
          {description}
          <div className={"next"+(this.state.user_inputs.num_people!=""?"":" disabled")} onClick={this.next_order_step.bind(this)}>Next →</div>
        </div>
      </div>);
    }else if(this.state.current_order_step == 3){

      var fruit_imagery="";
      var description="Our fruit is what we're known for, but we offer a variety of other products in our delivery service as well. Your box will be different each week depending on what's available & the box size you choose.";

      
      

      var render_options = [];
      Object.keys(box_options).map((item, key) => {

        render_options.push(<div className="option" data-value={box_options[item].value} onClick={e => this.confirm_step(e)} data-active={(this.state.user_inputs.box_options[item]==true?"true":"false")}>
          <div data-active={box_options[item].popular} className="recommendation">Most Popular</div>
          <div className="title"><span className="check">✓</span>{box_options[item].title}</div>
          <div className="subtitle">{box_options[item].subtitle}</div>
        </div>);
          
      });
      
      return (<div className="page-container">
        <div id="box_options_module" className="order_module small" data-faded={this.state.order_module_faded}>
          <img src={back_button} onClick={this.previous_order_step.bind(this)} className="back_button"/>
          <div className="question">What are you interested in receiving in your box? (choose as many as you'd like)</div>
          <div className="options">
            {render_options}
          </div>
          {fruit_imagery}
          <div className="description">{description}</div>
          <div className={"next"+(Object.keys(this.state.user_inputs.box_options).length > 0?"":" disabled")}  onClick={this.next_order_step.bind(this)}>Next →</div>
        </div>
      </div>);

    }else if(this.state.current_order_step == 4){
      console.log(this.state.user_inputs.box_options);
      var num_people_adjusted = Number(this.state.user_inputs.num_people);
      if(Object.keys(this.state.user_inputs.box_options).length > 2 || this.state.user_inputs.box_options["spirulina"] == true || this.state.user_inputs.box_options["olive_oil"] == true){
        num_people_adjusted = Number(num_people_adjusted)+1;
      };
      var recommended_size = calculate_recommended_box_size(this.state.current_order_type,String(num_people_adjusted));

      var people_options = '';
      var description = '';
      if(this.state.current_order_type == "Business"){
        people_options = people_options_table["Business"];
        description = 'For your '+people_options[this.state.user_inputs.num_people]+' employees, considering your box options we recommend trying the $'+recommended_size+' box. If you want your employees to have more food & greater variety, you can go one size up. Note: The amount & variety of items will change week to week depending on what is available and which box size you choose.';
      }else if(this.state.current_order_type == "Residential"){
        people_options = people_options_table["Residential"];
        description = 'For your '+people_options[this.state.user_inputs.num_people]+' people, considering your box options we recommend trying the $'+recommended_size+' box. If you want to have more food & greater variety, you can go one size up. Note: The amount & variety of items will change week to week depending on what is available and which box size you choose.';
      }
      
      
      

      var render_sizes = [];
      box_sizes.map((item, key) => {
        render_sizes.push(<div className="option" data-value={item} onClick={e => this.confirm_step(e)} data-active={(this.state.user_inputs.box_size==item?"true":"false")}>
          <div data-active={(item==recommended_size?"true":"false")} className="recommendation">Recommended</div>
          <div className="title"><span className="check">✓</span>${item}</div>
          <div className="subtitle">Per Week</div>
        </div>);
          
      });
      

      return (<div className="page-container">
        <div id="box_size_module" className="order_module small" data-faded={this.state.order_module_faded}>
          <img src={back_button} onClick={this.previous_order_step.bind(this)} className="back_button"/>
          <div className="question">What size would you like?</div>
          <div className="options">
            {render_sizes}
          </div>
          {fruit_imagery}
          <div className="description">{description}</div>
          <div className={"next"+(this.state.user_inputs.box_size!=""?"":" disabled")}  onClick={this.next_order_step.bind(this)}>Next →</div>
        </div>
      </div>);

    }else if(this.state.current_order_step == 5){

      var description = "We need your email to stay in touch, and your phone number to let you know when your delivery arrives.";
      if(is_valid_phone(this.state.user_inputs.phone) && is_valid_email(this.state.user_inputs.email)){
        description="✅ Nice! We'll only contact you by text regarding deliveries. Check your email for our weekly newsletter to see what's coming in your box.";
      }

      return(<div className="page-container">
        <div id="customer_info_module" className="order_module small" data-faded={this.state.order_module_faded}>
          <img src={back_button} onClick={this.previous_order_step.bind(this)} className="back_button"/>
          <div className="question">Contact Info</div>
          <input autocomplete="on" value={this.state.user_inputs.name} data-input="name" data-approved={(this.state.user_inputs.name!=""?"true":"false")} type="text" name="name" className="main_input" placeholder="Jane Diaz" onKeyPress={e => this.handleEnterPress(e)} onChange={e => this.confirm_step(e)}/>
          <input autocomplete="on" value={this.state.user_inputs.phone} data-input="phone" data-approved={is_valid_phone(this.state.user_inputs.phone)} type="tel" name="phone" className="main_input" placeholder="123-456-7890" onKeyPress={e => this.handleEnterPress(e)} onChange={e => this.confirm_step(e)}/>
          <input autocomplete="on" value={this.state.user_inputs.email} data-input="email" data-approved={is_valid_email(this.state.user_inputs.email)} className="main_input" type="email" name="email" placeholder="name@email.com" onKeyPress={e => this.handleEnterPress(e)} onChange={e => this.confirm_step(e)}/>
          <div className="description">{description}</div>
          <br/><br/>
          <div className={"next"+((is_valid_phone(this.state.user_inputs.phone) && is_valid_email(this.state.user_inputs.email) && this.state.user_inputs.name!="")?"":" disabled")}  onClick={this.next_order_step.bind(this)}>Next →</div>
        </div>
      </div>);

    }else if(this.state.current_order_step == 6){

      var description = "Please provide the street address you'd like to have your order delivered to. If we need any special instructions (like a door code) to reach you, please list those underneath.";
      if(this.state.user_inputs.street_address!=""){
        description="✅ Awesome! We'll let you know beforehand which day we'll be delivering.";
      }

      return(<div className="page-container">
        <div id="delivery_info_module" className="order_module small" data-faded={this.state.order_module_faded}>
          <img src={back_button} onClick={this.previous_order_step.bind(this)} className="back_button"/>
          <div className="question">Delivery Info</div>
          <input autocomplete="on" value={this.state.user_inputs.street_address} data-input="street_address" data-approved={(this.state.user_inputs.address!=""?"true":"false")} type="address" name="address" className="main_input" placeholder="1234 Mission St." onKeyPress={e => this.handleEnterPress(e)} onChange={e => this.confirm_step(e)}/>
          <input value={this.state.user_inputs.delivery_instructions} data-input="delivery_instructions" type="text" className="main_input" placeholder="Delivery Instructions" onKeyPress={e => this.handleEnterPress(e)} onChange={e => this.confirm_step(e)}/>
          <br/><br/>
          <label for="special_requests">Any special requests?</label>
          <input value={this.state.user_inputs.special_requests} data-input="special_requests" id="special_requests" type="text" className="main_input" placeholder="ex: no cherries" onKeyPress={e => this.handleEnterPress(e)} onChange={e => this.confirm_step(e)}/>
          <div className="description">{description}</div>
          <br/><br/>
          <div className={"next"+(this.state.user_inputs.address!=""?"":" disabled")}  onClick={this.next_order_step.bind(this)}>Next →</div>
        </div>
      </div>);

    }else if(this.state.current_order_step == 7){

      var description="We pick up from the farmers markets on Sundays & Wednesdays, so delivery on those days will come straight from the market to your door. We recommend Sunday deliveries as Sunday markets have the most variety.";
      
      var render_options = [];

      var order_delivery_days = delivery_days;

      if(this.state.valid_zip_area != "San Francisco"){
        order_delivery_days = {
          Wednesday:{
            value:"Wednesday",
            title:"Wednesday",
            subtitle:"Pickup Day"
          }
        };
        description="Wednesday is currently our delivery day for our East Bay clients. However, we'll soon be expanding this to even more days as we bring on more East Bay clients!";
      }

      Object.keys(order_delivery_days).map((item, key) => {

        render_options.push(<div className="option" data-value={order_delivery_days[item].value} onClick={e => this.confirm_step(e)} data-active={(this.state.user_inputs.delivery_days[item]==true?"true":"false")}>
          <div data-active={order_delivery_days[item].popular} className="recommendation">Most Popular</div>
          <div className="title"><span className="check">✓</span>{order_delivery_days[item].title}</div>
          <div className="subtitle">{order_delivery_days[item].subtitle}</div>
        </div>);
          
      });
      
      return (<div className="page-container">
        <div id="delivery_days_module" className="order_module small" data-faded={this.state.order_module_faded}>
          <img src={back_button} onClick={this.previous_order_step.bind(this)} className="back_button"/>
          <div className="question">Which delivery days do you prefer? <br/>(you may choose multiple)</div>
          <div className="options">
            {render_options}
          </div>
          <div className="description">{description}</div>
          <div className={"next"+(Object.keys(this.state.user_inputs.delivery_days).length > 0?"":" disabled")}  onClick={this.next_order_step.bind(this)}>Next →</div>
        </div>
      </div>);

    }else if(this.state.current_order_step == 8){

      //if(this.state.valid_zip_area == "San Francisco"){
        //San Francisco, payment at time of delivery

        var promo_response = "";
        if(this.state.user_inputs.promo_code!=""){
          if(this.state.user_inputs.valid_promo!=false){
            promo_response = (<span className="valid_promo">Code Applied: {this.state.user_inputs.valid_promo}</span>);
            var promo_text = promo_code_responses[this.state.user_inputs.promo_code.toUpperCase()];
          }else{
            promo_response = (<span className="invalid_promo">Invalid Code</span>);
          }
        }

        var selected_options = Object.keys(this.state.user_inputs.box_options);
        for(var i = 0; i < selected_options.length; i++){
          selected_options[i] = box_options[selected_options[i]].title;
        }
        var location = (this.state.current_order_type=="Business"?"business":"home");
        var area = this.state.valid_zip_area;

        return (<div className="page-container">
          <div id="payment_order_module" className="order_module small" data-faded={this.state.order_module_faded}>
            <img src={back_button} onClick={this.previous_order_step.bind(this)} className="back_button"/>
            <div className="question">Complete Your Order</div>
            <br/><br/>
            <label for="special_requests">Promo Code? {promo_response}</label>
            <input value={this.state.user_inputs.promo_code} data-input="promo_code" id="promo_code" type="text" className="main_input" placeholder="PROMO" onKeyPress={e => this.handleEnterPress(e)} onChange={e => this.confirm_step(e)}/>
            <div className="description">Sweet! Your <b>${this.state.user_inputs.box_size} Weekly {this.state.current_order_type} Box</b> with {(selected_options.length > 1?selected_options.slice(0, -1).join(", ")+" and "+selected_options.slice(-1)[0]:selected_options[0])} will be delivered to your {location} in {area} starting next week. We will text you to let you know when your box will arrive. Your full payment is due in cash, Venmo, or Paypal at or before the time of delivery. After you complete your order, check your inbox for a confirmation email with payment instructions. <span className="valid_promo">{promo_text}</span></div>
            <br/>
            <input type="checkbox" id="agreement" name="agreement" data-input="checkbox" value="agreed" checked={!!this.state.user_inputs.agreement} onChange={e => this.confirm_step(e)}/>
            <label htmlFor="agreement" id="agreement_label"><b>I agree to pay ${this.state.user_inputs.box_size} in cash, Venmo, or Paypal on delivery</b></label>
            <br/><br/><br/><br/>
            <div className={"next"+(!!this.state.user_inputs.agreement?"":" disabled")} onClick={this.add_order_to_firebase.bind(this)}>Place My Order →</div>
          </div>
        </div>);

      /*}else{
        //Oakland, allow payment by paypal
        var selected_options = Object.keys(this.state.user_inputs.box_options);
        for(var i = 0; i < selected_options.length; i++){
          selected_options[i] = box_options[selected_options[i]].title;
        }
        var location = (this.state.current_order_type=="Business"?"business":"home");
        var area = this.state.valid_zip_area;

        return (<div className="page-container">
          <div id="payment_order_module" className="order_module small" data-faded={this.state.order_module_faded}>
            <img src={back_button} onClick={this.previous_order_step.bind(this)} className="back_button"/>
            <div className="question">Complete Your Purchase</div>
            <div className="description">Sweet! Your <b>${this.state.user_inputs.box_size} {this.state.current_order_type} Box</b> with {(selected_options.length > 1?selected_options.slice(0, -1).join(", ")+" and "+selected_options.slice(-1)[0]:selected_options[0])} will be delivered to your {location} in {area} the week after you make your first payment. We will text you to let you know when your box will arrive. </div>
            <div id="paypal-button-container"></div>
          </div>
        </div>);
      }*/

    }else if(this.state.current_order_step == "COMPLETE"){


        return (<div className="page-container">
          <div id="order_complete_module" className="order_module small" data-faded={this.state.order_module_faded}>
            <div className="question">Order Complete!</div>
            <div className="description">Thanks for ordering with us! We're excited to bring you your first delivery starting next week. In the meantime, look out for our weekly newsletter to find out what's on the menu.<br/><br/><b>Check your email for our order confirmation email with payment instructions.</b></div>
            <br/>
            <div id="gif"></div>
            <div className={"next"} onClick={this.close_completed_order.bind(this)}>Close</div>
          </div>
        </div>);
    }
  }
  render() {
      
    return <div id="order_main_module" style={{background:"url('"+order_bg+"') center bottom no-repeat",backgroundSize:"cover"}}>
          {this.render_order_module()}
        </div>;

  }
}


export default Order_Module;



function is_valid_zipcode(zipcode){
  var isValidZip = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zipcode);
  return isValidZip;
}

function is_valid_phone(phone){
  var isValidPhone = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(phone);
  return isValidPhone;
}

function is_valid_email(email){
  var isValidEmail = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(email);
  return isValidEmail;
}

function is_approved_zipcode(zipcode){
  var valid_code = false;
  var can_deliver = false;
  if(valid_zip_codes.sf.includes(zipcode)){
    valid_code = "San Francisco";
    can_deliver = true;
  }else if(valid_zip_codes.treasure_island.includes(zipcode)){
    valid_code = "Treasure Island";
    can_deliver = false;
  }else if(valid_zip_codes.oakland.includes(zipcode)){
    valid_code = "Oakland";
    can_deliver = true;
  }else if(valid_zip_codes.berkeley.includes(zipcode)){
    valid_code = "Berkeley";
    can_deliver = true;
  }else if(valid_zip_codes.alameda.includes(zipcode)){
    valid_code = "Alameda";
    can_deliver = true;
  }else if(valid_zip_codes.oakland_ext.includes(zipcode)){
    valid_code = "Eastern Oakland";
    can_deliver = false;
  }else{
    valid_code = "No Delivery";
    can_deliver = false;
  }
  return {area:valid_code,can_deliver:can_deliver};
}




var paypal_links = {
  P35:"https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-3WU996949M866033GL4N3LDY",
  P50:"https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-2JL43517GW761800CL4N3LVI",
  P75:"https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-23V918858V020140NL4N3MGY",
  P100:"https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-9NK72544HP7331235L4N3M7A",
  P150:"https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-07286042U78018946L4N3NZQ",
  P200:"https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-2EC513422W447545UL4N3ORA",
  P250:"https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-58D4540910303620VL4N3PBQ",
  P300:"https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-4PB38104SG679950AL4N3KSQ"
}

var paypal_links_10_OFF = {
  P35:"https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-2GC89001R9185720HL4N4ASI",
  P50:"https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-1EY95277BS401090HL4N4BAQ",
  P75:"https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-0XY55759SV7592335L4N4BSA",
  P100:"https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-6UC86359M55137734L4N4CAY",
  P150:"https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-8DT41341T32531054L4N4CNQ",
  P200:"https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-6K315127NN089725RL4N4H3A",
  P250:"https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-40V910903C3899920L4N4LMI",
  P300:"https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-11M62492557399603L4N4IIA"
}

var paypal_links_50_OFF = {
  P35:"https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-7UN30502NB213945NL4N3ZXA",
  P50:"https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-8HL92642LT2700335L4N34NQ",
  P75:"https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-8A101310NE1673320L4N33KY",
  P100:"https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-6EU00446W99923708L4N342I",
  P150:"https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-9VC1105698041883RL4N35PA",
  P200:"https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-0SX15394EL709914HL4N36YA",
  P250:"https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-3US617624E3936949L4N4LAI",
  P300:"https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-2XS06736WL4300547L4N37SI"
}



