import React from 'react';
import './About.css';
import profile_pic_konstantin from './img/Konstantin_Pic.png';
import profile_pic_shenandoah from './img/Shenandoah_Pic.png';
//import profile_pic from './img/konstantin_profile.png';
//import profile_pic_chris from './img/christopher_profile_12.png';
import profile_pic_chris from './img/Christopher_Pic.png';


import philosophy_symbol from './img/philosophy_symbol.png';
import high_quality_symbol from './img/high_quality_symbol.png';
import harmless_symbol from './img/harmless_symbol.png';
import happiness_symbol from './img/happiness_symbol.png';
import transparent_symbol from './img/transparent_symbol.png';
import community_symbol from './img/community_symbol.png';
import healthy_symbol from './img/healthy_symbol.png';


class About extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {

    if(this.props.currentPage != "about"){
      return false;
    }
      
    return <section id="about" className="hidden">
      <div className="page-container">
        <div className="section_title">
            <div className="title_text">GET TO KNOW US</div>
            <div className="title_subtext upper"></div>
        </div>
        <div class="worker_section">
          <div className="col2">
            <img src={profile_pic_chris}/>
          </div>
          <div className="col2">
            <div className="wrap">
              <div className="name">Christopher Paniati, Founder</div>
              <div className="description">
                Christopher started Fruit By The Bay after working with Konstantin Kosov, the founder of Spirit Fruit. After the coronavirus pandemic led to the shutdown of SF businesses, Christopher worked with Konstantin to rebuild his fruit delivery service for SF households. As the service grew, Christopher branched out to start Fruit By The Bay to deliver farmer's market goods to folks in the East Bay.
                <br/><br/>
                Before starting Fruit By The Bay, Christopher worked as a product & UX designer in the bay area. Christopher is a nature lover, and spends time gardening, backpacking, building furniture and custom campervans, and playing with his dog Bailey.
              </div>
            </div>
          </div>
        </div>
        <div class="worker_section">
          <div className="col2">
            <img src={profile_pic_shenandoah}/>
          </div>
          <div className="col2">
            <div className="wrap">
              <div className="name">Shenandoah Lush, Operations</div>
              <div className="description">
                Originally from Homer Alaska, Shenandoah joined Fruit By The Bay in 2020 shortly after moving to the Bay Area. Shen is a hands-on collaborator after working with all kinds of small businesses, from food trucks to fishing boats. Shenandoah is also an experienced barista having worked at and managed cafes across the country. 
                <br/><br/>
                Shenandoah helped get FBTB off the ground, and she manages all kinds of day to day operations from delivery to communications. When she's not working, Shen is a comedy aficionado, an amateur psychologist, and a dabbler in embroidery.
              </div>
            </div>
          </div>
        </div>
        <div class="worker_section">
          <div className="col2">
            <img src={profile_pic_konstantin}/>
          </div>
          <div className="col2">
            <div className="wrap">
              <div className="name">Konstantin Kosov, Partner</div>
              <div className="description">
                Konstantin is an honored partner of Fruit By The Bay, and the founder of <a href="http://www.spiritfruit.co" target="_blank">Spirit Fruit</a> (the SF version of FBTB). Konstantin is a San Francisco native who has been delivering fruit for more than 5 years, and his work with Spirit Fruit helped inspire Fruit By The Bay to serve the East Bay community. 
              </div>
            </div>
          </div>
        </div>
        <div id="philosophy">
          <div className="section_title">
            <img src={philosophy_symbol}/>
            <div className="title_text">Our Philosophy</div>
            <div className="description">Fruit by the Bay is on a mission to inspire communities centered around the values of honest, sustainable, locally sourced, and high quality produce. Every human deserves to have access to healthy food, and we believe in nurturing that right. </div>
          </div>
          <div className="section_body">
            <div className="row">
              <div className="col2">
                <div className="wrap">
                  <div className="name"><img src={high_quality_symbol}/>High Quality</div>
                  <div className="description">
                  We really care about quality in all facets of our operation. From our genuine relationships with farms and markets, through our handpicked selection of produce, to our conscious delivery methods, Fruit by the Bay goes the extra mile (by bicycle!) to bring only the best fruit & produce to you.
                  </div>
                </div>
              </div>
              <div className="col2">
                <div className="wrap">
                  <div className="name"><img src={harmless_symbol}/>Harmless</div>
                  <div className="description">
                  These days it takes a lot of work and research to find companies that you can feel good about buying from. With Fruit by the Bay, we aim to take this concern out of the equation by providing a service that is completely free of harm for all of our constituents. 
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col2">
                <div className="wrap">
                  <div className="name"><img src={healthy_symbol}/>Healthy</div>
                  <div className="description">
                  Our team selects the best of the best at local markets in and around the Bay. We coordinate with Californian farmers to deliver a diverse variety of healthy and wholesome produce. Fruit by the Bay deliveries are always in season, always organic, and always healthy. 
                  </div>
                </div>
              </div>
              <div className="col2">
                <div className="wrap">
                  <div className="name"><img src={transparent_symbol}/>Transparent</div>
                  <div className="description">
                  Our food systems in the U.S. tend to have very little transparency, and that’s a problem. We believe that transparency is the key to empowering our communities to invest in products and services that align with their values. Fruit by the Bay aims for complete transparency about where our food comes from, and how it gets to you.
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col2">
                <div className="wrap">
                  <div className="name"><img src={happiness_symbol}/>Happiness</div>
                  <div className="description">
                  Nothing beats the feeling of biting into a perfectly ripe fruit on a temperate summer day – we truly believe that good fruit is an essential element to a happy life. We strive to create happiness in every aspect of our business, from our relationship with our growers, to our co-workers, and of course our customers.
                  </div>
                </div>
              </div>
              <div className="col2">
                <div className="wrap">
                  <div className="name"><img src={community_symbol}/>Community</div>
                  <div className="description">
                  Fruit by the Bay believes that good food calls for good company. We aim to use our platform as a delivery service to bridge neighborhoods together on the common goal of eating well, and being happy. 
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>;

  }
}


export default About;

