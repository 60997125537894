import React from 'react';
import Firebase from 'firebase';
import config from './Config';
import Contact from './Contact.js';
import Header from './Header.js';
import Home from './Home.js';
import Order from './Order.js';
import About from './About.js';
import Produce from './Produce.js';
import './App.css';
/*import 'https://cdnjs.cloudflare.com/ajax/libs/svg.js/3.0.16/svg.min.js';*/

const importAll = require =>
  require.keys().reduce((acc, next) => {
    acc[next.replace("./", "")] = require(next);
    return acc;
  }, {});

const farmer_images = importAll(
  require.context("./img/farmer_images", false, /\.(png|jpe?g|svg)$/)
);

const fruit_images_small = importAll(
  require.context("./img/fruit_images/small", false, /\.(png|jpe?g|svg)$/)
);
/*
const Project_Images = importAll(
  require.context("./img/projects", false, /\.(png|jpe?g|svg)$/)
);

const Pretty_Images = importAll(
  require.context("./img/pretty_things", false, /\.(png|jpe?g|svg)$/)
);

const Mira_Images = importAll(
  require.context("./img/projects/Mira", false, /\.(png|jpe?g|svg|gif)$/)
);*/

console.log(farmer_images);


////////////////////////////////////
// REACT APP FUNCTIONS /////////////
////////////////////////////////////



var main_pages = {
  contact:{Title:"Contact",Key:"contact"},
  produce:{Title:"Our Produce",Key:"produce"},
  about:{Title:"About Us",Key:"about"},
  order:{Title:"Order",Key:"order"},
  home:{Title:"Home",Key:"home"}
};

var farmer_data = {
  miramonte:{
    name:"Miramonte Farms",
    location:"San Juan Bautista, CA",
    goods:"Greens, fruit",
    img:"miramonte.jpg",
    description:"The Teresi and Rasmussen families have a long history of farming in the Santa Clara valley, mostly growing prunes, apricots and cherries. Phil Teresi and Grace Rasmussen first met at Farm Bureau events for local young farmers and ranchers. They reconnected 10 years later after each had been involved with a handful of other farming and food related ventures. For the past 20 years they have been farming 12 acres in San Benito County. They are most proud of their dry farmed tomatoes and fruit, saving water and producing flavor-packed organic produce."
  },
  galpin:{
    name:"Galpin Farms",
    location:"Reedley, CA",
    goods:"Peaches, mandarins, oranges, other stonefruit",
    img:"galpin.jpg",
    description:"Lisa and Anthony Galpin are second-generation farmers in California's Central Valley. Based in Reedley, the Galpins grow a variety of stone fruit, citrus and grapes, mainly for a handful of farmers' markets in Southern California. Their family has also been a staple at the famous Alemany Farmers' Market (just a few blocks from the Good Eggs Food Hub!) since the late 70's."
  },
  MA:{
    name:"M. A. Farms",
    location:"Davis, CA",
    goods:"Peaches, Asian pears, mulberries",
    img:"MA.jpg",
    description:"Mohammed Afzal has been farming for over 30 years. In 2000 he started M. A. Farms, which grows seasonal varieties of stone fruits, pomegranates, persimmons, citrus, and limited quantities of mulberries. M. A. Farms specializes in more than six varieties of asian pears - Mohammed was one of the first farmers to start growing them in the USA 30 years ago, so the quality of his fruit reflects this experience."
  },
  triple:{
    name:"Triple Delight Berries",
    location:"Fresno, CA",
    goods:"Blueberries",
    img:"triple.png",
    description:"Since 1997, the Sorensens have been selling their hand-picked blueberries at farmers markets throughout the Bay Area and the Central Valley as well as at their road side stand right at their farm located just south of Fresno, California. You can always count on Triple Delight Blueberries for that \"straight from the field\" taste."
  },
  stepladder:{
    name:"Stepladder Creamery",
    location:"Cambira, CA",
    goods:"Passionfruit, avocados, cheese",
    img:"stepladder3.jpg",
    description:"Stepladder Ranch & Creamery is home to our dairy and creamery located on the Central Coast of California. We make small-batch cheeses with milk from our herd of LaMancha goats and during the goat milk off-season from November through March, we make cow's milk cheeses from milk we source locally from the dairy science program at Cal Poly in San Luis Obispo."
  },
  bernard:{
    name:"Bernard Farms",
    location:"Riverside, CA",
    goods:"Citrus, avocados",
    img:"bernard.jpg",
    description:"Vince and Vicki Bernard began farming their land in 1979 and have been bringing their produce to market since 1980. Their land is farmed sustainably by relying on the use of beneficial insects, avoiding the use of synthetic pesticides or herbicides, and the use of drip irrigation systems. Their soil is fertilized with seaweed and urea and mushroom compost is added for an added hit of nitrogen compound – an element that fruit trees crave."
  },
  brokaw:{
    name:"Brokaw Farms",
    location:"Saticoy, CA",
    goods:"Avocados, guavas, passionfruit, cherimoya",
    img:"brokaw.jpg",
    description:"Brokaw Ranch Company specializes in intensively farmed avocado, specialty citrus, and subtropical tree-fruit production. The Brokaws endeavor to consistently produce plentiful crops of top-quality fruit while being good stewards of all resources: soil, water, and people. They try to avoid using synthetic chemicals for pest control but do use them when organic control methods are not effective."
  },
  rhodes:{
    name:"Country Rhodes Family Farm",
    location:"Davis, CA",
    goods:"Avocados, peaches, grapes",
    img:"rhodes.jpg",
    description:"Country Rhodes Farm is truly a family farm. The property has been in the Rhodes Family for generations, and David and I moved back to the farm in 2000. David drives a transport truck while I manage the farm. The Rhodes Family is involved in many aspects of the regional and local community."
  },
  rainbow:{
    name:"Rainbow Orchards",
    location:"Camino, CA",
    goods:"Apples and pears",
    img:"rainbow.jpg",
    description:"Rainbow Orchards is a 3rd generation family farm based in Camino, California. Our Apple Hill℠ farm has provided the finest mountain grown fruit since 1977 – for over 40 years! We grow the best apples, pears, peaches, nectarines, and blueberries you’ve ever tasted."
  },
  kalkat:{
    name:"Kalkat Nut and Fruit",
    location:"Live Oak, CA",
    goods:"Kiwi, French plums, dried French plums",
    img:"kalkat.png",
    description:"Kalkat Nut & Fruit produces, dehydrates, and packs delicious plums and prunes. They've been established as one of the best in California since 2013."
  },
  yerena:{
    name:"Yerena Berry Farm",
    location:"Watsonville, CA",
    goods:"Strawberry, raspberry, blackberry",
    img:"yerena.jpg",
    description:"After growing strawberries for Driscoll with their parents for 12 years, Apolinar and Gilberto decided to lease their own farm. They began in 1982 growing conventionally, but in response to market demand converted five trial acres to organic. The organic fruit sold so well they eventually decided to transition the farm to organic."
  }
};



class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: "home"/*,
      projects: {...case_data, ...pretty_data }*/
    };
  }
  componentDidMount(){
    window.addEventListener('scroll', this.handleScroll.bind(this));
    //checkPosition();
    //setup_titles();

    
    //check page URL for page assignment when page is loaded
    if(main_pages.hasOwnProperty(window.location.pathname.replace("/", ""))){
      console.log('valid page!!!');
      /*var elmnt = document.getElementById("header");
      elmnt.scrollIntoView();*/
      this.setState({currentPage:window.location.pathname.replace("/", "")});
    }
      
      //detect URL change
      window.addEventListener("popstate", function(e) {

        //navigate to correct page
        console.log('url change');
        if(main_pages.hasOwnProperty(window.location.pathname.replace("/", ""))){
          console.log('valid page!!!');
          /*var elmnt = document.getElementById("header");
          elmnt.scrollIntoView();*/
          this.setState({currentPage:window.location.pathname.replace("/", "")});
        }
      }.bind(this));
    
  }
  componentWillUnmount(){
    window.removeEventListener('scroll', this.handleScroll);
  }
  handleScroll(e){
    
    return false;

    if(!this.state.projectIsOpen){

      var new_page = false;

      if(window.scrollY > document.getElementById("contact").offsetTop-150){
        new_page = "contact";
      }else if(window.scrollY > document.getElementById("about").offsetTop-150){
        new_page = "about";
      }else if(window.scrollY > document.getElementById("pretty").offsetTop-150){
        new_page = "pretty";
      }else if(window.scrollY > document.getElementById("case").offsetTop-150){
        new_page = "case";
      }else{
        new_page = "home";
      }

      if(new_page != this.state.currentPage){
        this.setState({
          currentPage: new_page
        });
        setURL(new_page);
      }

    }

  }
  disableScroll(){
    document.getElementsByTagName('body')[0].classList.add('noScroll');
  }
  enableScroll(){
    document.getElementsByTagName('body')[0].classList.remove('noScroll');
  }
  closeProject(e){
    this.changePage(this.state.previous_page,e, true);
    this.enableScroll();
  }
  changePage(new_page,e, dontScroll){
    if(new_page != this.state.currentPage){
      if(new_page == "contact"){
        var elmnt = document.getElementById(new_page);
        elmnt.scrollIntoView();
        return (false);
      }
      setURL(new_page);
      this.setState({
        currentPage: new_page
      },function(){
        var elmnt = document.getElementById("header");
        elmnt.scrollIntoView();
      });
    }
  }
  render() {

    // MOVE THIS STUFF TO ITS OWN COMPONENT LATER ////////////////////

    

    // RENDER SERVICES SECTIONS //////////////////////////////////////
    
    /*<ProjectViewer
          currentPage = {this.state.currentPage}
          changePage={this.changePage.bind(this)}
          toggleProject={this.toggleProject.bind(this)}
           projectIsOpen={this.state.projectIsOpen}
           project_images={Project_Images}
           current_project={this.state.currentProject}
           project_data={Projects[this.state.currentProject]}
        />*/
    
    
    return (
      <div id="App" className="App">
        <Header
        main_pages={main_pages}
        currentPage={this.state.currentPage}
        changePage={this.changePage.bind(this)}
        />
        <Home
        currentPage={this.state.currentPage}
        changePage={this.changePage.bind(this)}
        farmer_images={farmer_images}
        farmer_data={farmer_data}/>
        <Order
        currentPage={this.state.currentPage}/>
        <About
        currentPage={this.state.currentPage}/>
        <Produce
        currentPage={this.state.currentPage}
        farmer_images={farmer_images}
        farmer_data={farmer_data}/>
        <Contact
        currentPage={this.state.currentPage}/>
      </div>
    );
  }
}

/*
<div id="clients">
          <div className="page-container">
            <div id="clients_title">We've Worked With:</div>
            <div id="clients_logos"></div>
          </div>
        </div>*/



export default App;






////////////////////////////////////
// EXTRA FUNCTIONS /////////////////
////////////////////////////////////



function setURL(pageName){
  console.log('Setting URL...');
  window.history.pushState({
    id: pageName
  }, pageName+' | Hellopress', pageName);
}






window.addEventListener("load", function(){
  //document.getElementById("App-header-video").play();
  //document.getElementById("App-header-video").classList.remove('wait');
  /*document.getElementById("App-header-video").addEventListener('loadeddata', function() {
    
  }, false);*/
});


  //breaks text into multiple span's by word
  function setup_titles(){
    var elements = document.querySelectorAll('h1');
    for (var i = 0; i < elements.length; i++) {
      var elem = elements[i];
      var array = [];
      for(var j = 0, childs = elem.childNodes; j < childs.length; j ++) {
        if (childs[j].nodeType === 3 /* document.TEXT_NODE */) {
          array = array.concat(childs[j].nodeValue.trim().split(/\s+/));
        } else {
          array.push(childs[j].outerHTML);
        }
      }
      var new_text = "";
      for(var k = 0; k < array.length; k++) {
        new_text += ("<span>"+array[k]+"</span> ");
      }
      elem.innerHTML = new_text;
    }
  }




  //functions to set sticky nav dep. on page scroll position
  var elements;
  var windowHeight;

  function init() {
    windowHeight = window.innerHeight;
  }

  function checkPosition() {
    /*
    var navbar = document.getElementById("nav");
    var navwrapper = document.getElementById("nav_wrapper");
    if(typeof(navbar) != 'undefined' && navbar != null){
      if(window.scrollY > navwrapper.offsetTop){
        navbar.classList.add("sticky");
      }else{
        navbar.classList.remove("sticky");
      }
    } 

    var app_el = document.getElementById("App");
    if(typeof(app_el) != 'undefined' && app_el != null){
      if(window.scrollY > document.getElementById("home").offsetHeight-400){
        app_el.classList.add("scrolled");
      }else{
        app_el.classList.remove("scrolled");
      }
    } 

    elements = document.querySelectorAll('.hidden');
    for (var i = 0; i < elements.length; i++) {
      var element = elements[i];
      var positionFromTop = elements[i].getBoundingClientRect().top;

      if (positionFromTop - windowHeight + 350 <= 0) {
        element.classList.add('fade-in-element');
        element.classList.remove('hidden');
      }
    }
    */
  }

  window.addEventListener('scroll', checkPosition);
  window.addEventListener('resize', init);

  init();
  checkPosition();





  
  