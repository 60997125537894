import React from 'react';
import './Produce.css';
import InSeason from './InSeason.js';/*
import farmer_pic1 from './img/K&J.jpg';
import farmer_pic2 from './img/Medina+Berries.jpg';*/


class Produce extends React.Component {
  constructor(props) {
    super(props);
  }
  render_farmers(){

    var farmer_content = [];
    var farmer_images=this.props.farmer_images;
    var farmer_data=this.props.farmer_data;
    
    Object.keys(farmer_data).map((item, key) => {
      
      farmer_content.push(<div className="farmer">
          <div className="col2">
            <img src={farmer_images[farmer_data[item].img]}/>
          </div>
          <div className="col2">
            <div className="farmer_info">
              <div className="name">{farmer_data[item].name}</div>
              <div className="description">{farmer_data[item].description}</div>
            </div>
          </div>
        </div>);

    });

    return farmer_content;

  }
  render() {

    if(this.props.currentPage != "produce"){
      return false;
    }
      
    return <section id="produce" className="hidden">
        <InSeason/>
        <div className="page-container">
        <div className="section_title">
            <div className="title_text">OUR FARMERS</div>
            <div className="title_subtext upper">Meet</div>
        </div>
        <div id="farmer_section">
          {this.render_farmers()}
        </div>
      </div>
    </section>;

  }
}


export default Produce;

